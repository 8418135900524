import { NetworkStatus, useLazyQuery, useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useMemo } from "react";
import { Pagination } from "../../../components/Pagination/Pagination";
import { usePaginationState } from "../../../hooks/usePaginationState";
import PageLayout from "../../../layouts/PageLayout";
import { GET_TICKETS } from "../../../schemas/queries/Tickets";
import TicketCard from "./TicketCard";
import TicketFilters from "./TicketFilters";

interface QueryResult {
	hubspotTickets: {
		items: HubspotTicket[];
		count: number;
	};
}

interface HubspotTicket {
	archived: boolean;
	attachments: string[];
	cellName: string;
	callIdentifier: string;
	callName: string;
	closed: boolean;
	content: string;
	createdTime: number;
	hasAttachments: boolean;
	id: string;
	portalUser: string;
	reconciled: boolean;
	sourceSystem: string;
	sourceSystemDetailsUrl: string;
	sourceSystemId: string;
	stage: string;
	subject: string;
	updatedTime: number;
	__typename: string;
}

export default function ViewTickets() {
	const [statusFilter, setStatusFilter] = React.useState("All");
	const [order, setOrder] = React.useState(-1);
	const [subjectQuery, setSubjectQuery] = React.useState("");
	const [cellNameQuery, setCellNameQuery] = React.useState("");

	const { loading, error, data, networkStatus, refetch } =
		useQuery<QueryResult>(GET_TICKETS, {
			variables: {
				skip: 0,
				limit: 6,
				stages: statusFilter === "All" ? [] : [statusFilter],
				sortBy: order,
				subject: subjectQuery,
				cellName: cellNameQuery,
			},
			fetchPolicy: "network-only",
			notifyOnNetworkStatusChange: true,
		});

	const [fetchNextPage, { loading: loadingNextPage }] =
		useLazyQuery<QueryResult>(GET_TICKETS, {
			fetchPolicy: "network-only",
		});

	const totalItems = data?.hubspotTickets.count || 0;
	const {
		skip,
		limit,
		setSkip,
		reset: resetPagination,
	} = usePaginationState(0, 6, totalItems);

	const handleSkipChange = useCallback(
		(newSkip: number) => {
			setSkip(newSkip);
			refetch({
				skip: newSkip * limit,
				limit,
				stages: statusFilter === "All" ? [] : [statusFilter],
				sortBy: order,
				subject: subjectQuery,
				cellName: cellNameQuery,
			});
		},
		[
			refetch,
			limit,
			statusFilter,
			order,
			subjectQuery,
			cellNameQuery,
			setSkip,
		],
	);

	// Prefetch next page
	useEffect(() => {
		if (skip < Math.floor(totalItems / limit)) {
			fetchNextPage({
				variables: {
					skip: (skip + 1) * limit,
					limit,
					stages: statusFilter === "All" ? [] : [statusFilter],
					sortBy: order,
					subject: subjectQuery,
					cellName: cellNameQuery,
				},
			});
		}
	}, [
		skip,
		limit,
		totalItems,
		fetchNextPage,
		statusFilter,
		order,
		subjectQuery,
		cellNameQuery,
	]);

	useEffect(() => {
		const pollInterval = setInterval(() => {
			refetch();
		}, 10000);

		return () => clearInterval(pollInterval);
	}, [refetch]);

	const paginatedTickets = useMemo(() => {
		if (!data) return [];
		return data.hubspotTickets.items;
	}, [data]);

	const handleSort = useCallback(() => {
		setOrder((prevOrder) => prevOrder * -1);
		resetPagination();
	}, [resetPagination]);

	const handleSubjectQueryChange = useCallback(
		(query: string) => {
			setSubjectQuery(query);
			resetPagination();
		},
		[resetPagination],
	);

	const handleCellNameQueryChange = useCallback(
		(query: string) => {
			setCellNameQuery(query);
			resetPagination();
		},
		[resetPagination],
	);

	const handleStatusFilterChange = useCallback(
		(newStatus: string) => {
			setStatusFilter(newStatus);
			resetPagination();
		},
		[resetPagination],
	);

	const renderLoadingState = useCallback(() => {
		if (networkStatus === NetworkStatus.loading) {
			return <p>Loading tickets for the first time...</p>;
		} else if (networkStatus === NetworkStatus.setVariables) {
			return <p>Refreshing ticket data...</p>;
		} else {
			return <p>Loading...</p>;
		}
	}, [networkStatus]);

	if (loading && networkStatus === NetworkStatus.loading)
		return renderLoadingState();
	if (error) return <p>Error: {error.message}</p>;

	return (
		<PageLayout
			title="View Support Tickets"
			description="View all support tickets."
			buttonText="Add Ticket"
			buttonLink="/support/tickets/add"
		>
			<TicketFilters
				statusFilter={statusFilter}
				setStatusFilter={handleStatusFilterChange}
				order={order}
				handleSort={handleSort}
				subjectQuery={subjectQuery}
				setSubjectQuery={handleSubjectQueryChange}
				cellNameQuery={cellNameQuery}
				setCellNameQuery={handleCellNameQueryChange}
			/>
			<div className="grid grid-cols-1 gap-4 mb-10 auto-rows-fr sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
				{paginatedTickets.map((ticket: HubspotTicket) => (
					<TicketCard key={ticket.id} {...ticket} />
				))}
			</div>
			<Pagination
				skip={skip}
				onSkip={handleSkipChange}
				count={totalItems}
				limit={limit}
				currentAmount={paginatedTickets.length}
			/>
		</PageLayout>
	);
}
