import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import LoadingSpinner from "../../components/LoadingSpinner";
import Pagination from "../../components/Pagination/Pagination";
import { useTimeframe } from "../../hooks/timeframe";
import { usePaginationState } from "../../hooks/usePaginationState";
import { GET_EVENTS } from "../../schemas/queries/Events";
import { useCustomerStore } from "../../store/Customer";
import { timeframes } from "../../utils/timestamps";
import RecentEvent from "./RecentEvent";

export default function RecentEvents() {
	// State
	const [timeRange, setTimeRange] = useState([]);
	const [initialLoading, setInitialLoading] = useState(true);
	const [events, setEvents] = useState([]);
	const [count, setCount] = useState(0);

	// Hooks
	const navigate = useNavigate();
	const { timeframe } = useTimeframe();
	const { customerFilter } = useCustomerStore(
		useShallow((state) => ({
			customerFilter: state.customerFilter,
		})),
	);
	const { skip, limit, handleSkipChange, reset } = usePaginationState(
		0,
		6,
		count,
		{
			timeStart: timeRange[0],
			timeEnd: timeRange[1],
			customers: customerFilter,
		},
	);

	useEffect(() => {
		// on time range update or customer filter change
		setTimeRange(timeframes[timeframe]());
		// also set pagination properties back to defaults
		reset();
		setInitialLoading(true);
	}, [timeframe, reset]);

	const { loading, refetch } = useQuery(GET_EVENTS, {
		variables: {
			skip: skip * limit,
			limit,
			timeStart: timeRange[0],
			timeEnd: timeRange[1],
			customers: customerFilter,
		},
		pollInterval: 10000, // poll every 10 seconds
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			setEvents(data.events.items);
			setCount(data.events.count);
			setInitialLoading(false);
		},
		onError: (error) => {
			console.error(error.message);
			navigate("/error", {
				state: {
					status: 404,
					error: "Something went wrong querying for Events.",
				},
			});
		},
	});

	return (
		<div className="py-16 space-y-16 xl:space-y-20">
			<div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
				<div className="max-w-2xl mx-auto lg:mx-0 lg:max-w-none">
					<div className="flex items-center justify-between">
						<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
							Recent Events
						</h2>
						<div className="flex items-center justify-between">
							{!initialLoading && (
								<>
									<p className="mx-2 text-sm font-semibold dark:text-gray-200">
										{events.length} of {count} Events
									</p>
									<Link
										to="/events"
										className="mr-4 text-sm font-semibold leading-6 text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 dark:hover:text-indigo-300"
									>
										View all
									</Link>
								</>
							)}
						</div>
					</div>
					<ul
						role="list"
						className="grid grid-cols-1 mt-6 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
					>
						{initialLoading ? (
							<div className="mx-auto my-auto lg:col-span-3">
								<LoadingSpinner w={24} h={24} />
							</div>
						) : (
							events.map((event) => {
								return (
									<RecentEvent
										key={event.id}
										eventData={event}
									/>
								);
							})
						)}
						{!initialLoading && events.length === 0 && (
							<h3 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">
								No events found.
							</h3>
						)}
					</ul>
					{!initialLoading && count > 6 && (
						<Pagination
							skip={skip}
							onSkip={(newSkip) =>
								handleSkipChange(newSkip, refetch)
							}
							count={count}
							limit={limit}
							currentAmount={events.length}
							itemType="events"
							queryFilters={{
								timeStart: timeRange[0],
								timeEnd: timeRange[1],
								customers: customerFilter,
							}}
							className="mt-8"
						/>
					)}
				</div>
			</div>
		</div>
	);
}
