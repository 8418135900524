import { useQuery } from "@apollo/client";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AccessControlledComponent } from "../../components/AccessControlledComponent";
import { DetailsHeader } from "../../components/Headers/DetailsHeader";
import ScreenLoader from "../../components/Loaders/ScreenLoader";
import LoadingSpinner from "../../components/LoadingSpinner";
import {
	DetailsOverview,
	DetailsOverviewTerm,
} from "../../components/Overviews/DetailsOverview";
import { Tag } from "../../components/Tags";
import { POLICIES } from "../../constants/Policies";
import { GET_DEVICE } from "../../schemas/queries/Devices";
import { GET_ENTITY_PHOTOS } from "../../schemas/queries/EntityPhotos";
import { DeviceEditModal } from "./DeviceEditModal";
import { DeviceMetrics } from "./DeviceMetrics";

const parseInferenceLevel = (inferenceLevel) => {
	let parsedInferenceLevel;
	switch (inferenceLevel) {
		case "LOW":
		case 0:
			parsedInferenceLevel = "Low";
			break;
		case "STANDARD":
		case 1:
			parsedInferenceLevel = "Standard";
			break;
		case "HIGH":
		case 2:
			parsedInferenceLevel = "High";
			break;
		default:
			parsedInferenceLevel = "N/A";
			break;
	}
	return parsedInferenceLevel;
};

export const DeviceDetails = () => {
	const navigate = useNavigate();
	const [initialLoading, setInitialLoading] = useState(true);
	const { deviceId } = useParams();
	const [venueId, setVenueId] = useState("");
	const [shortUrlId, setShortUrlId] = useState("");
	const [photo, setPhoto] = useState("");

	const { data, refetch } = useQuery(GET_DEVICE, {
		variables: {
			id: deviceId,
		},
		pollInterval: 10000,
		notifyOnNetworkStatusChange: true,
		onError: (error) => {
			console.error(error);
			navigate("/error", {
				state: { status: 404, error: "Device could not be found." },
			});
		},
		onCompleted: (data) => {
			setVenueId(data.device.venue);
			setShortUrlId(data.device.detailsPageShortUrl);
			setInitialLoading(false);
		},
	});

	const entityQuery = useQuery(GET_ENTITY_PHOTOS, {
		variables: {
			entityId: venueId,
			includeThumbnail: false,
		},
		onCompleted: (data) => {
			setPhoto(data.entityPhotos[0].url);
		},
		onError: (error) => {
			console.error(error.message);
		},
	});

	if (initialLoading) {
		return <ScreenLoader />;
	}

	return (
		<main>
			<DetailsHeader>
				<div className="flex items-center gap-x-6">
					{entityQuery.loading ? (
						<LoadingSpinner className="w-16 h-16 mx-auto" />
					) : (
						<img
							src={photo}
							alt={data.device.venueName}
							className="flex-none w-16 h-16 rounded-full ring-1 ring-gray-900/10 dark:text-gray-300"
						/>
					)}

					<h1 className="text-left">
						<div className="flex flex-row gap-x-4">
							<div className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
								{data.device?.customerDeviceName
									? data.device?.customerDeviceName
									: data.device?.name}
							</div>
							<DeviceEditModal
								deviceId={data.device.id}
								deviceName={data.device.name}
								customerFacingName={
									data.device?.customerDeviceName
								}
								refetch={refetch}
							/>
						</div>
						<div className="text-sm leading-6 text-gray-800 dark:text-gray-300">
							{data.device.venueName}
						</div>
						<div className="text-sm leading-6 text-gray-800 dark:text-gray-300">
							{data.device?.customerDeviceName
								? data.device?.name
								: data.device.location}
						</div>
					</h1>
				</div>
				<div className="flex-col items-end justify-between hidden ml-auto text-right sm:flex">
					<div className="text-sm font-semibold leading-6 text-gray-800 dark:text-gray-300">
						Most Recent Event
					</div>
					<Link
						to={`/events/${data.device.mostRecentEvent}`}
						className="text-sm font-normal hover:underline dark:text-gray-300"
					>
						{data.device.mostRecentEventName}
					</Link>
				</div>
			</DetailsHeader>
			<div className="px-4 py-16 mx-auto max-w-7xl sm:px-6 lg:px-8">
				<div className="grid items-start max-w-2xl grid-cols-1 grid-rows-1 mx-auto gap-x-8 gap-y-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
					<div className="space-y-4 lg:col-start-1 lg:row-end-1">
						{/* hide lifetime stats since it takes too long */}
						{/* <DeviceOverview id={deviceId} /> */}
						<DetailsOverview>
							{data.deviceConfig?.hardwareVersion && (
								<DetailsOverviewTerm
									term="Hardware"
									description={
										data.deviceConfig?.hardwareVersion
									}
								/>
							)}
							{data.deviceConfig?.inferenceLevel !== null && (
								<DetailsOverviewTerm
									term="Inference Mode"
									description={parseInferenceLevel(
										data.deviceConfig?.inferenceLevel,
									)}
								/>
							)}
							{data.deviceConfig?.facialRecognitionEnabled !==
								null && (
								<AccessControlledComponent
									policies={[POLICIES.READ_PEOPLE]}
								>
									<DetailsOverviewTerm
										term="Facial Recognition"
										description={
											<Tag
												color={
													data.deviceConfig
														?.facialRecognitionEnabled
														? "green"
														: "gray"
												}
												tag={
													data.deviceConfig
														?.facialRecognitionEnabled
														? "Enabled"
														: "Disabled"
												}
											/>
										}
									/>
								</AccessControlledComponent>
							)}
						</DetailsOverview>
						{/* <DetailsOverviewTerm
        term={"Lifetime Arrivals"}
        description={data.deviceMetric.arrivals}
      />
      <DetailsOverviewTerm
        term={"Lifetime Alerts"}
        description={data.deviceMetric.alerts}
      />
      <DetailsOverviewTerm
        term={"Lifetime Threats"}
        description={data.deviceMetric.threats}
      />
      <DetailsOverviewTerm
        term={"Lifetime Recognized"}
        description={data.deviceMetric.recognized}
      /> */}
						{/* Keep QR Code on side on lg devices */}
						{/* <div className="hidden lg:block">
              <DeviceShortUrl
                id={shortUrlId}
                sharedIdentifier={data.device.sharedIdentifier}
              />
            </div> */}
					</div>
					<div className="lg:col-span-2 lg:row-span-2 lg:row-end-2">
						<DeviceMetrics id={deviceId} />
					</div>
					{/* Keep QR Code at bottom of screen if on mobile */}
					{/* <div className="block lg:hidden">
            <DeviceShortUrl
              id={shortUrlId}
              sharedIdentifier={data.device.sharedIdentifier}
            />
          </div> */}
				</div>
			</div>
		</main>
	);
};

export default DeviceDetails;
