import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { AnimatePresence, motion } from "framer-motion";
import React, { FC, useEffect, useMemo, useState } from "react";

type PageButtonProps = {
	children: React.ReactNode;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	isActive: boolean;
	disabled: boolean;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "onClick">;

const PageButton: React.FC<PageButtonProps> = ({
	children,
	onClick,
	isActive,
	disabled,
}) => (
	<motion.button
		whileHover={{ scale: 1.05 }} // Subtle scale
		whileTap={{ scale: 0.95 }}
		onClick={onClick}
		disabled={disabled}
		aria-current={isActive ? "page" : undefined}
		aria-label={
			typeof children === "number"
				? `Page ${children}`
				: children === <ChevronLeftIcon />
					? "Previous Page"
					: "Next Page"
		}
		role="button"
		className={`
      w-8 h-8 flex items-center justify-center rounded-xl
      text-sm transition-colors duration-200
      ${
			isActive
				? "text-black bg-blue-50 dark:bg-blue-500/50 dark:text-white font-medium ring-1 ring-inset ring-blue-600/10"
				: "text-gray-600 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-800"
		}
      ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
    `}
	>
		{children}
	</motion.button>
);

interface PaginationProps {
	skip: number;
	onSkip: (page: number) => void;
	count: number;
	limit: number;
	currentAmount: number;
	itemType?: string;
	singleItemType?: string;
	queryFilters?: Record<string, any>;
	className?: string;
}

export const Pagination: FC<PaginationProps> = ({
	skip,
	onSkip,
	count,
	limit,
	currentAmount,
	itemType = "results",
	singleItemType = "result",
	queryFilters = {},
	className = "",
}) => {
	// Calculate the total number of pages
	const pages = useMemo(() => {
		return Array.from(
			{ length: Math.ceil(count / limit) },
			(_, i) => i + 1,
		);
	}, [count, limit]);

	const currentPage = skip + 1;
	const totalPages = Math.ceil(count / limit);

	const [direction, setDirection] = useState(0);
	const [isAnimating, setIsAnimating] = useState(false);
	const [previousQuery, setPreviousQuery] = useState<Record<string, any>>({});

	const handlePageclick = (page: number) => {
		if (isAnimating) return;
		setIsAnimating(true);
		setDirection(page > currentPage ? -1 : 1);
		onSkip(Math.min(pages.length, page - 1));
		setTimeout(() => setIsAnimating(false), 300);
	};

	// If the query filters change, reset the Pagination to the first page
	useEffect(() => {
		if (
			queryFilters &&
			previousQuery &&
			JSON.stringify(queryFilters) !== JSON.stringify(previousQuery)
		) {
			handlePageclick(1);
			setPreviousQuery(queryFilters);
		}
	}, [queryFilters, handlePageclick, setPreviousQuery]);

	return (
		<motion.div
			initial={{ opacity: 0, y: 20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.5 }}
			className={`${className} relative overflow-hidden shadow-lg rounded-lg p-4 border border-slate-200 dark:border-slate-700`}
		>
			<AnimatePresence>
				{isAnimating && (
					<motion.div
						key="flash"
						initial={{
							x: direction > 0 ? "-100%" : "100%",
							opacity: 0,
						}}
						animate={{ x: 0, opacity: 1 }}
						exit={{
							x: direction > 0 ? "100%" : "-100%",
							opacity: 0,
						}}
						transition={{
							duration: 0.3,
							ease: "easeInOut",
						}}
						className="absolute inset-0 bg-gradient-to-r from-transparent via-black/[0.02] to-transparent dark:via-white/[0.03] pointer-events-none"
					/>
				)}
			</AnimatePresence>
			<div className="flex flex-col items-center justify-between space-y-4 sm:flex-row sm:space-y-0">
				{count === 0 ? (
					<p className="text-sm text-slate-600 dark:text-slate-300">
						No{" "}
						{singleItemType ??
							(itemType.charAt(itemType.length - 1) === "s"
								? itemType.slice(0, -1)
								: itemType)}{" "}
						found
					</p>
				) : count === 1 ? (
					<p className="text-sm text-slate-600 dark:text-slate-300">
						<span className="font-semibold text-slate-800 dark:text-slate-100">
							1
						</span>{" "}
						{singleItemType ??
							(itemType.charAt(itemType.length - 1) === "s"
								? itemType.slice(0, -1)
								: itemType)}
					</p>
				) : (
					<p className="text-sm text-slate-600 dark:text-slate-300">
						<span className="font-semibold text-slate-800 dark:text-slate-100">
							{count === 0 ? 0 : limit * skip + 1}
						</span>{" "}
						-{" "}
						<span className="font-semibold text-slate-800 dark:text-slate-100">
							{limit * skip + currentAmount}
						</span>{" "}
						of{" "}
						<span className="font-semibold text-slate-800 dark:text-slate-100">
							{count}
						</span>{" "}
						{itemType}
					</p>
				)}

				<div className="flex items-center space-x-2">
					{/* Previous button */}
					<PageButton
						onClick={() =>
							handlePageclick(Math.max(1, currentPage - 1))
						}
						disabled={currentPage === 1}
						isActive={false}
					>
						<ChevronLeftIcon className="w-4 h-4" />
					</PageButton>
					<div className="flex items-center gap-x-1">
						{pages.length < 8 ? (
							pages.map((page) => (
								<PageButton
									key={page}
									onClick={() => handlePageclick(page)}
									isActive={currentPage === page}
									disabled={false}
								>
									{page}
								</PageButton>
							))
						) : (
							<>
								{/* Show the first page */}
								<PageButton
									key="first"
									onClick={() => handlePageclick(1)}
									isActive={currentPage === 1}
									disabled={false}
								>
									1
								</PageButton>

								{/* Show the second previous page */}
								{currentPage > 4 ? (
									<span className="py-1 text-slate-400 dark:text-slate-500">
										...
									</span>
								) : (
									<PageButton
										key="secondPrevious"
										onClick={() => handlePageclick(2)}
										isActive={currentPage === 2}
										disabled={false}
									>
										2
									</PageButton>
								)}

								{/* Previous, Current, and Next Pages */}
								{currentPage < 4 ? (
									// Near start
									<>
										<PageButton
											key="3"
											onClick={() => handlePageclick(3)}
											isActive={currentPage === 3}
											disabled={false}
										>
											3
										</PageButton>
										<PageButton
											key="4"
											onClick={() => handlePageclick(4)}
											isActive={currentPage === 4}
											disabled={false}
										>
											4
										</PageButton>
										<PageButton
											key="5"
											onClick={() => handlePageclick(5)}
											isActive={currentPage === 5}
											disabled={false}
										>
											5
										</PageButton>
									</>
								) : currentPage > totalPages - 3 ? (
									// Near end
									<>
										<PageButton
											key={totalPages - 4}
											onClick={() =>
												handlePageclick(totalPages - 4)
											}
											isActive={
												currentPage === totalPages - 4
											}
											disabled={false}
										>
											{totalPages - 4}
										</PageButton>
										<PageButton
											key={totalPages - 3}
											onClick={() =>
												handlePageclick(totalPages - 3)
											}
											isActive={
												currentPage === totalPages - 3
											}
											disabled={false}
										>
											{totalPages - 3}
										</PageButton>
										<PageButton
											key={totalPages - 2}
											onClick={() =>
												handlePageclick(totalPages - 2)
											}
											isActive={
												currentPage === totalPages - 2
											}
											disabled={false}
										>
											{totalPages - 2}
										</PageButton>
									</>
								) : (
									// Middle
									<>
										<PageButton
											key={currentPage - 1}
											onClick={() =>
												handlePageclick(currentPage - 1)
											}
											isActive={false}
											disabled={false}
										>
											{currentPage - 1}
										</PageButton>
										<PageButton
											key={currentPage}
											isActive={true}
											disabled={false}
										>
											{currentPage}
										</PageButton>
										<PageButton
											key={currentPage + 1}
											onClick={() =>
												handlePageclick(currentPage + 1)
											}
											isActive={false}
											disabled={false}
										>
											{currentPage + 1}
										</PageButton>
									</>
								)}

								{/* Show the second next page */}
								{currentPage < totalPages - 3 ? (
									<span className="py-1 text-slate-400 dark:text-slate-500">
										...
									</span>
								) : (
									<PageButton
										key="secondNext"
										onClick={() =>
											handlePageclick(totalPages - 1)
										}
										isActive={
											currentPage === totalPages - 1
										}
										disabled={false}
									>
										{totalPages - 1}
									</PageButton>
								)}

								{/* Show the last page */}
								<PageButton
									key="last"
									onClick={() => handlePageclick(totalPages)}
									isActive={currentPage === totalPages}
									disabled={false}
								>
									{totalPages}
								</PageButton>
							</>
						)}
					</div>

					{/* Next button */}
					<PageButton
						onClick={() =>
							handlePageclick(
								Math.min(totalPages, currentPage + 1),
							)
						}
						disabled={currentPage === totalPages || count === 0}
						isActive={false}
					>
						<ChevronRightIcon className="w-4 h-4" />
					</PageButton>
				</div>
			</div>
		</motion.div>
	);
};

export default Pagination;
