import React from "react";
import PageLayout from "./PageLayout";
import XonarLogo from "../assets/images/logo_header.png";
import { motion } from "framer-motion";

type Props = {};

const LoadingLayout = () => {
	return (
		<PageLayout>
			<motion.img
				src={XonarLogo}
				alt="Xonar Logo"
				className="flex items-center justify-center my-auto transition-opacity size-40"
				animate={{ opacity: [0.5, 1, 0.5] }} // Keyframes for opacity
				transition={{
					duration: 2, // Duration of one cycle (in seconds)
					repeat: Infinity, // Infinite loop
					repeatType: "loop", // Ensures the animation loops smoothly
					ease: "easeInOut", // Smooth easing function
				}}
			/>
		</PageLayout>
	);
};

export default LoadingLayout;
