import Arrivals from "./Arrivals";
import RecentEvents from "./RecentEvents";
import Stats from "./Stats";
import { TimeProvider } from "./TimeProvider";
import { Timestamp } from "../../components/Timestamp";

export const Home = () => {
	return (
		<TimeProvider>
			<main>
				<div className="relative pt-16 overflow-hidden isolate">
					<header className="pt-6 pb-4 sm:pb-6">
						<div className="flex flex-wrap items-center gap-3 px-4 mx-auto max-w-7xl md:gap-6 sm:flex-nowrap sm:px-6 lg:px-8">
							<Arrivals />
							<Timestamp />
						</div>
					</header>
					<Stats />
				</div>
				<RecentEvents />
			</main>
		</TimeProvider>
	);
};

export default Home;
