import React, { useMemo } from "react";
import { Link } from "react-router-dom";

type TrainingCardProps = {
	className?: string;
	title: string;
	description: string;
	videoId: string;
};

const getThumbnailLink = (id: string) => {
	return `https://i1.ytimg.com/vi/${id}/maxresdefault.jpg`;
};

const TrainingCard: React.FC<TrainingCardProps> = (props) => {
	const thumbnailLink = useMemo(
		() => getThumbnailLink(props.videoId),
		[props.videoId]
	);
	return (
		<div className="flex flex-col sm:flex-row sm:min-w-[38rem] justify-between gap-2 sm:gap-0 py-4">
			<section className="flex flex-col gap-2">
				<h2 className="text-base font-medium">{props.title}</h2>
				<p className="text-sm font-light text-pretty max-w-[24rem]">
					{props.description}
				</p>
			</section>
			<Link to={`/support/training/${props.videoId}`}>
				<section className="relative w-full h-full sm:w-72 group hover:cursor-pointer">
					<img
						src={thumbnailLink}
						alt="Thumbnail"
						className="object-cover transition-opacity rounded-md shadow-sm group-hover:cursor-pointer group-hover:opacity-50 hover:cursor-pointer"
					/>
					<div className="absolute inset-0 flex items-center justify-center align-middle transition-opacity duration-300 bg-opacity-50 rounded-md opacity-0 group-hover:opacity-100">
						<button className="px-6 py-2 my-auto text-white bg-red-500 rounded-full hover:bg-red-600">
							▶
						</button>
					</div>
				</section>
			</Link>
		</div>
	);
};

export default TrainingCard;
