import React from "react";
import PageLayout from "../../../layouts/PageLayout";
import TrainingCard from "./TrainingCard";
import { TrainingVideos } from "../../../constants/TrainingVideos";

const Training = () => {
	return (
		<PageLayout
			title="Training Videos"
			description="Training videos to assist you with your Xonar devices and apps."
		>
			<div className="grid grid-cols-1 gap-4 divide-y-2 divide-gray-100 dark:divide-gray-600">
				{TrainingVideos.map((video) => (
					<TrainingCard key={video.videoId} {...video} />
				))}
			</div>
		</PageLayout>
	);
};

export default Training;
