import { AccessControlledComponent } from "../../components/AccessControlledComponent";
import { Category } from "./AddCategory";
import { DeletePerson } from "./DeletePerson";
import { Link } from "react-router-dom";
import { POLICIES } from "../../constants/Policies";
import React from "react";
import { TAG_COLORS } from "../../constants/Colors";

export const PeopleCategory: React.FC<Category> = ({ color, label }) => {
	return (
		<span
			className={`rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset ${
				TAG_COLORS[color.toLowerCase()]
			}`}
		>
			{label}
		</span>
	);
};

export const PeopleTableRow = ({ personData }) => {
	return (
		<tr>
			<td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-gray-200 sm:pl-6">
				<Link
					to={`/people/${personData.id}`}
					className="mr-2 text-inherit hover:text-indigo-600 dark:hover:text-gray-400"
				>
					<span className="sr-only">View {personData.name}</span>
					{personData.name}
				</Link>
			</td>
			<td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap dark:text-gray-300">
				<PeopleCategory
					color={personData.category.color}
					label={personData.category.label}
				/>
			</td>
			<td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell whitespace-nowrap dark:text-gray-300">
				{personData.alert ? "Yes" : "No"}
			</td>
			<td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell whitespace-nowrap dark:text-gray-300">
				{personData.email}
			</td>
			<td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell whitespace-nowrap dark:text-gray-300">
				{personData.phone}
			</td>
			<td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
				<AccessControlledComponent policies={[POLICIES.EDIT_PEOPLE]}>
					<Link
						to={`/people/${personData.id}/edit`}
						className="mx-2 text-indigo-600 hover:text-indigo-900"
					>
						Edit<span className="sr-only"> {personData.name}</span>
					</Link>
				</AccessControlledComponent>
				<AccessControlledComponent policies={[POLICIES.DELETE_PEOPLE]}>
					<DeletePerson
						personId={personData.id}
						name={personData.name}
						className="ml-2 text-indigo-600 hover:text-indigo-900"
					/>
				</AccessControlledComponent>
			</td>
		</tr>
	);
};

export default PeopleTableRow;
