import React, { useState, useMemo } from "react";
import { useQuery, NetworkStatus } from "@apollo/client";
import { usePortalUserStore } from "../../../store/PortalUser";
import { useCustomerStore } from "../../../store/Customer";
import { useShallow } from "zustand/react/shallow";
import { GET_REPORTS } from "../../../schemas/queries/Reports";
import { GET_USER_VENUES } from "../../../schemas/queries/Venues";
import { DynamicLoadingSpinner } from "../../../components/LoadingSpinner";
import { MultiSelect } from "../../../components/Menus/MultiSelect";
import PageLayout from "../../../layouts/PageLayout";
import { ReportCard } from "./ReportCard";
import { Switch } from "@headlessui/react";
import { usePaginationState } from "../../../hooks/usePaginationState";
import { Pagination } from "../../../components/Pagination/Pagination";
import { useReportStore } from "../../../store/Report";

export const Reports = () => {
	// const [userOnly, setUserOnly] = useState(false);
	const { userOnly, setUserOnly, venues, setVenues } = useReportStore(
		useShallow((state) => state),
	);

	const { customerFilter } = useCustomerStore(useShallow((state) => state));
	const { portalUser } = usePortalUserStore(useShallow((state) => state));
	const [venueItems, setVenueItems] = useState<any[]>([]);

	const selectedCustomer = useMemo(() => {
		if (customerFilter.length > 0) {
			return customerFilter;
		} else if (
			portalUser &&
			portalUser?.customers &&
			portalUser?.customers.length > 0
		) {
			return portalUser.customers;
		} else {
			return [];
		}
	}, [customerFilter, portalUser]);

	const selectedVenue = useMemo(() => {
		if (venues.length > 0) {
			const venueIds = venues.map((v) => v.id);
			return venueIds;
		}
		return [];
	}, [venues]);

	const userVenuesQuery = useQuery(GET_USER_VENUES, {
		variables: {
			customers: selectedCustomer,
		},
		notifyOnNetworkStatusChange: true,
		onCompleted(data) {
			setVenueItems(data.userVenues);
		},
	});

	const [reportCount, setReportCount] = useState(0);

	const { skip, limit, setSkip, setLimit, reset, currentPage, totalPages } =
		usePaginationState(0, 5, reportCount);

	const reportsQuery = useQuery(GET_REPORTS, {
		variables: {
			skip: skip * limit,
			limit,
			userOnly,
			customers: selectedCustomer,
			venues: selectedVenue,
		},
		pollInterval: 10000,
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			setReportCount(data?.getReports?.count);
		},
	});

	return (
		<PageLayout
			title="Reports"
			description="Your generated event reports that can be shared with others."
		>
			{(userVenuesQuery.loading &&
				userVenuesQuery.networkStatus === NetworkStatus.loading) ||
			(reportsQuery.loading &&
				reportsQuery.networkStatus === NetworkStatus.loading) ? (
				<div className="w-full">
					<DynamicLoadingSpinner className="w-20 h-20" />
				</div>
			) : (
				<div className="flex flex-col w-full">
					<div className="flex flex-col justify-between mb-2 gap-y-4 sm:flex-row">
						<div className="w-full max-w-64">
							<MultiSelect
								value={venues}
								onChange={(e) => {
									reset();
									setVenues(e);
								}}
								itemType="venue"
								items={venueItems}
							/>
						</div>
						<Switch.Group>
							<div className="flex items-center ml-1 gap-x-2 sm:ml-0">
								<Switch.Label>All Reports</Switch.Label>
								<Switch
									checked={userOnly}
									onChange={() => {
										reset();
										setUserOnly(!userOnly);
									}}
									className={`${
										userOnly
											? "bg-indigo-600 dark:bg-indigo-500"
											: "bg-gray-200 dark:bg-gray-600"
									} relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
								>
									<span
										className={`${
											userOnly
												? "translate-x-6"
												: "translate-x-1"
										} inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
									/>
								</Switch>
							</div>
						</Switch.Group>
					</div>
					<ul
						role="list"
						className="divide-y divide-gray-100 dark:divide-gray-200/20"
					>
						{reportsQuery.loading &&
						reportsQuery.networkStatus ===
							NetworkStatus.setVariables ? (
							<div className="py-2">
								<DynamicLoadingSpinner className="w-20 h-20" />
							</div>
						) : (
							// <>
							// 	{reportsQuery.data?.getReports?.items.map(
							// 		(report) => (
							// 			<ReportCard
							// 				key={report.id}
							// 				{...report}
							// 			/>
							// 		)
							// 	)}
							// </>
							<>
								{reportsQuery.data?.getReports?.count > 0 ? (
									reportsQuery.data?.getReports?.items.map(
										(report) => (
											<ReportCard
												key={report.id}
												{...report}
											/>
										),
									)
								) : (
									<div className="flex items-center py-4">
										<p className="px-2">
											No reports found.
										</p>
									</div>
								)}
							</>
						)}
					</ul>
					<div className="w-full py-4">
						<Pagination
							skip={skip}
							limit={limit}
							onSkip={setSkip}
							count={reportCount}
							currentAmount={
								reportsQuery?.data?.getReports?.items
									? reportsQuery?.data?.getReports?.items
											.length
									: 1
							}
						/>
					</div>
				</div>
			)}
		</PageLayout>
	);
};

export default Reports;
