import React, { useMemo, useState } from "react";
import { useShallow } from "zustand/react/shallow";
import { WalkModal } from "../../../components/Modals/WalkModal";
import { Pagination } from "../../../components/Pagination/Pagination";
import { useReportStore } from "../../../store/Report";
import { ReportRegisteredThreatWalk } from "../../../store/Report/typing";
import { getTime } from "../../../utils/timestamps";
import { toCapitalized } from "../../../utils/ux";

const ReportRegisteredThreatCategory: React.FC<ReportRegisteredThreatWalk> = (
	props,
) => {
	const REDS = ["GUN", "KNIFE"];
	const GREENS = ["NON_WEAPON"];
	const colorClass = (category: string) => {
		if (REDS.includes(category)) {
			return "text-red-700 rounded-md ring-1 ring-inset bg-red-50 dark:text-red-300 dark:bg-red-500/50 ring-red-600/10";
		}

		if (GREENS.includes(category)) {
			return "text-green-700 rounded-md ring-1 ring-inset bg-green-50 dark:text-green-300 dark:bg-green-500/50 ring-green-600/10";
		}

		return "text-gray-700 rounded-md ring-1 ring-inset bg-gray-50 dark:text-gray-300 dark:bg-gray-500/50 ring-gray-600/10";
	};
	const spanText = (category: string, name: string) => {
		let objectType = "Non-Weapon";
		if (REDS.includes(category)) {
			objectType = "Weapon";
		}
		return `${objectType} / ${toCapitalized(name)}`;
	};
	return (
		<>
			{props?.annotatedSubjectAreaContents &&
				props?.annotatedSubjectAreaContents.map((threat, i) => {
					const { object } = threat;
					return (
						<div key={`${props.id}-${i}`}>
							<span
								className={`px-2 py-1 text-xs font-medium rounded-md ring-1 ring-inset ${colorClass(
									object.category,
								)}`}
							>
								{spanText(object.category, object.name)}
							</span>
						</div>
					);
				})}
		</>
	);
};

const ReportRegisteredThreatsTableRow: React.FC<
	ReportRegisteredThreatWalk & { onClick: () => void }
> = ({ onClick, ...props }) => {
	const { report } = useReportStore(useShallow((state) => state));

	const time = useMemo(() => {
		return {
			datetime: new Date(props.entryTime).toISOString(),
			displayed: getTime(props.entryTime, report?.metadata?.timeZoneId),
		};
	}, [props, report]);

	const detectedColor = () => {
		const color = props?.objectOfInterestPresent ? "green" : "red";
		return `text-${color}-700 bg-${color}-50 dark:text-${color}-300 dark:bg-${color}-500/50 ring-${color}-600/10`;
	};

	return (
		<tr className="border-b border-gray-100 dark:border-gray-100/20">
			<td className="hidden py-5 pl-6 pr-0 text-left text-gray-700 align-top dark:text-gray-200 tabular-nums sm:table-cell">
				<time dateTime={time.datetime}>{time.displayed}</time>
			</td>
			<td className="hidden py-5 pl-8 pr-0 text-right text-gray-700 align-top dark:text-gray-200 tabular-nums sm:table-cell">
				{props?.customerDeviceName
					? props?.customerDeviceName
					: props?.cellName}
			</td>
			<td className="py-5 pl-8 pr-0 space-y-1 text-right align-top tabular-nums">
				<ReportRegisteredThreatCategory {...props} />
			</td>
			<td className="py-5 pl-8 pr-0 text-right align-top tabular-nums">
				<span
					className={`px-2 py-1 text-xs font-medium rounded-md ring-1 ring-inset ${detectedColor()}`}
				>
					{props?.objectOfInterestPresent ? "Yes" : "No"}
				</span>
			</td>
			<td className="py-5 pl-8 pr-0 text-right text-gray-700 align-top dark:text-gray-200/50 tabular-nums">
				<span>
					<svg
						className="w-5 h-5 hover:cursor-pointer"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
						onClick={onClick}
					>
						<path d="M3 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM8.5 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM15.5 8.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
					</svg>
				</span>
			</td>
		</tr>
	);
};

type ReportRegisteredThreatsTableProps = {
	order: 1 | -1;
	searchTerm: string;
};

const ReportRegisteredThreatsTable: React.FC<
	ReportRegisteredThreatsTableProps
> = (props) => {
	const { report } = useReportStore(useShallow((state) => state));

	// Pagination
	const [skip, setSkip] = useState(0);
	const [limit] = useState(5);

	// Modal
	const [open, setOpen] = useState(false);
	const [walk, setWalk] = useState<ReportRegisteredThreatWalk>();

	const [people, count] = useMemo(() => {
		if (!report) return [[], 0];
		const walksOrdered = report?.registeredThreats?.walks.sort((a, b) => {
			if (props.order === 1) {
				return a.entryTime - b.entryTime;
			} else {
				return b.entryTime - a.entryTime;
			}
		});
		const walks = walksOrdered.filter((walk) => {
			const walkObjects = walk?.annotatedSubjectAreaContents?.map((a) => {
				return a.object.name.trim().toLowerCase();
			});
			if (props.searchTerm) {
				return walkObjects?.some((s) =>
					s.includes(props.searchTerm.trim().toLowerCase()),
				);
			} else {
				return true;
			}
		});
		return [walks.slice(skip * limit, skip * limit + limit), walks.length];
	}, [props, report, skip, limit]);

	if (count < 1) {
		return (
			<>
				<div className="w-full py-8 mt-0 leading-6 text-center bg-white text-md whitespace-nowrap dark:bg-gray-900 dark:text-gray-300">
					No registered threats.
				</div>
			</>
		);
	}
	return (
		<>
			<table className="w-full mt-0 text-sm leading-6 text-left bg-white whitespace-nowrap dark:bg-gray-900">
				<thead className="text-gray-900 border-b border-gray-200 dark:text-gray-200 dark:border-gray-200/20">
					<tr>
						<th
							scope="col"
							className="hidden py-3 pl-6 pr-0 font-semibold text-left sm:table-cell"
						>
							Arrival Time
						</th>
						<th
							scope="col"
							className="hidden py-3 pl-8 pr-0 font-semibold text-right sm:table-cell"
						>
							Location
						</th>
						<th
							scope="col"
							className="py-3 pl-8 pr-0 font-semibold text-right"
						>
							Category
						</th>
						<th
							scope="col"
							className="py-3 pl-8 pr-0 font-semibold text-right"
						>
							Detected
						</th>
					</tr>
				</thead>
				<tbody>
					{people.map((walk) => {
						return (
							<ReportRegisteredThreatsTableRow
								key={walk.id}
								onClick={() => {
									setWalk(walk);
									setOpen(true);
								}}
								{...walk}
							/>
						);
					})}
				</tbody>
			</table>
			<Pagination
				skip={skip}
				onSkip={setSkip}
				count={count}
				limit={limit}
				currentAmount={people.length}
				itemType="threats"
			/>
			<WalkModal open={open} setOpen={setOpen} {...walk}>
				<div className="flex flex-col mt-4 gap-y-2">
					<h2 className="text-base font-medium text-gray-800 dark:text-gray-200">
						Items Detected
					</h2>
					{/* @ts-ignore */}
					<ReportRegisteredThreatCategory {...walk} />
				</div>
			</WalkModal>
		</>
	);
};

export const ReportRegisteredThreats = () => {
	const [order, setOrder] = useState<1 | -1>(1);

	const [searchTerm, setSearchTerm] = useState("");

	const handleSort = (e) => {
		e.preventDefault();
		if (order === 1) {
			setOrder(-1);
		} else {
			setOrder(1);
		}
	};

	return (
		<div className="mt-8 border border-gray-200 rounded-xl dark:border-gray-200/20">
			<div className="flex flex-row justify-between p-8 pt-6">
				<h2 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
					Registered Threats
				</h2>
				<div className="flex mt-2 rounded-md shadow-sm max-h-10">
					<div className="relative flex focus-within:z-10">
						<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
							<svg
								className="w-5 h-5 text-gray-400"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z" />
							</svg>
						</div>

						<input
							type="text"
							name="category"
							id="category"
							className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 bg-white text-gray-900 dark:bg-blue-xonar dark:text-gray-200 dark:border-white/10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							placeholder="Category"
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>

						<button
							type="button"
							onClick={handleSort}
							className="relative h-full rounded-l-none border-0 -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-5 text-sm font-semibold text-gray-900 dark:text-gray-200/50 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-blue-xonar/90"
						>
							{/* ASCENDING */}
							{order === 1 && (
								<svg
									className="-ml-0.5 h-5 w-5"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M2 3.75A.75.75 0 012.75 3h11.5a.75.75 0 010 1.5H2.75A.75.75 0 012 3.75zM2 7.5a.75.75 0 01.75-.75h6.365a.75.75 0 010 1.5H2.75A.75.75 0 012 7.5zM14 7a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02l-1.95-2.1v6.59a.75.75 0 01-1.5 0V9.66l-1.95 2.1a.75.75 0 11-1.1-1.02l3.25-3.5A.75.75 0 0114 7zM2 11.25a.75.75 0 01.75-.75H7A.75.75 0 017 12H2.75a.75.75 0 01-.75-.75z"
										clipRule="evenodd"
									/>
								</svg>
							)}
							{/* DESCENDING */}
							{order === -1 && (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth="1.5"
									stroke="currentColor"
									className="w-5 h-5"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25"
									/>
								</svg>
							)}
							Sort
						</button>
					</div>
				</div>
			</div>
			<ReportRegisteredThreatsTable
				order={order}
				searchTerm={searchTerm}
			/>
		</div>
	);
};

export default ReportRegisteredThreats;
