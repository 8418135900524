import { useCallback, useMemo, useState } from "react";

export const usePaginationState = (
	initialSkip = 0,
	initialLimit = 6,
	totalItems = 0,
) => {
	const [skip, setSkip] = useState<number>(initialSkip);
	const [limit, setLimit] = useState<number>(initialLimit);

	const handleSkipChange = useCallback(
		(newSkip: number, refetch) => {
			setSkip(newSkip);
			refetch({
				skip: newSkip * limit,
			});
		},
		[limit, setSkip],
	);

	const reset = useCallback(() => {
		setSkip(0);
	}, []);

	const currentPage = useMemo(
		() => Math.floor(skip / limit) + 1,
		[skip, limit],
	);
	const totalPages = useMemo(
		() => Math.ceil(totalItems / limit),
		[totalItems, limit],
	);

	return {
		skip,
		limit,
		setSkip,
		handleSkipChange,
		setLimit,
		reset,
		currentPage,
		totalPages,
	};
};
