import { motion } from "framer-motion";
import React from "react";

export const PeopleTableRowSkeleton = () => {
	return (
		<tr>
			<td colSpan={6} className="py-4 px-4 sm:pl-6 sm:pr-6">
				{/* The line height matches small text size */}
				<motion.div
					className="h-[1.25rem] w-full mx-auto bg-gray-200 dark:bg-gray-700 rounded opacity-10 [background-image:linear-gradient(90deg,transparent_0%,rgba(0,0,0,0.05)_50%,transparent_100%)] dark:[background-image:linear-gradient(90deg,transparent_0%,rgba(255,255,255,0.5)_50%,transparent_100%)]"
					initial={{ backgroundPosition: "100%" }}
					animate={{ backgroundPosition: "-100%" }}
					transition={{
						duration: 5,
						repeat: Infinity,
						ease: "linear",
					}}
					style={{
						backgroundSize: "200% 100%",
					}}
				/>
			</td>
		</tr>
	);
};
