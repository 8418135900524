import "./App.css";

import * as Sentry from "@sentry/react";

import { Route, Routes, useLocation } from "react-router-dom";

import { useEffect } from "react";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import { useShallow } from "zustand/react/shallow";
import { Navbar } from "./components/Navbar";
import { ProtectedComponent } from "./components/ProtectedComponent";
import { POLICIES } from "./constants/Policies";
import { useRole } from "./hooks/auth";
import { Devices } from "./pages/Devices";
import { DeviceDetails } from "./pages/Devices/DeviceDetails";
import { EditDevices } from "./pages/Devices/EditDevices";
import { ConfigPanel } from "./pages/Devices/EditDevices/Config/ConfigPanel";
import { InferencePanel } from "./pages/Devices/EditDevices/Inference/InferencePanel";
import { ModePanel } from "./pages/Devices/EditDevices/Mode/ModePanel";
import { PasscodePanel } from "./pages/Devices/EditDevices/Passcode/PasscodePanel";
import { SoftwarePanel } from "./pages/Devices/EditDevices/Software/SoftwarePanel";
import { Error } from "./pages/Error";
import { Events } from "./pages/Events";
import { EventDetails } from "./pages/Events/EventDetails";
import { Home } from "./pages/Home";
import { People } from "./pages/People";
import { AddBatch } from "./pages/People/AddBatch";
import { AddPerson } from "./pages/People/AddPerson";
import { EditPerson } from "./pages/People/EditPerson";
import { PeopleDetails } from "./pages/People/PeopleDetails";
import { Profile } from "./pages/Profile";
import SupportPage from "./pages/Support";
import ViewTickets from "./pages/Support/ViewTickets";
import { Venues } from "./pages/Venues";
import { VenueDetails } from "./pages/Venues/VenueDetails";
import { Report } from "./pages/Shared/Reports/Report";
import { Reports } from "./pages/Shared/Reports";
import DeviceSelectionPage from "./pages/Walks/DeviceSelection";
import WalksPage from "./pages/Walks/WalksPage";
import { useCustomerStore } from "./store/Customer";
import { usePortalUserStore } from "./store/PortalUser";
import { Landing } from "./pages/Landing";
import Training from "./pages/Support/Training";
import TrainingVideo from "./pages/Support/Training/TrainingVideo";

function App() {
	// fetch portalUser on app load
	usePortalUserStore.getState().fetchPortalUser();
	// fetch customers on app load
	useCustomerStore.getState().fetchCustomers();

	const { setPathPageView, setIdentity, setContentType } = useTrackingCode();
	const location = useLocation();
	const { portalUser } = usePortalUserStore(
		useShallow((state) => ({ portalUser: state.portalUser }))
	);

	const { isAdmin } = useRole(portalUser);

	useEffect(() => {
		setPathPageView(location.pathname);
	}, [location.pathname]);

	/* Hubspot tracking code integration */
	if (portalUser?.emailAddress) {
		setIdentity(portalUser.emailAddress);
		setContentType("site-page");
	}

	return (
		<>
			<Navbar />
			<Routes>
				<Route path="/" element={<Landing />} />
				<Route
					path="/home"
					element={
						<ProtectedComponent
							component={Home}
							policiesRequired={[POLICIES.READ_CUSTOMER_EVENTS]}
						/>
					}
				/>
				<Route path="/events">
					{/* In react-router v6, you have to set index true for components 
          in parent routes if you want to use nested routes */}
					<Route
						index
						element={
							<ProtectedComponent
								component={Events}
								policiesRequired={[
									POLICIES.READ_CUSTOMER_EVENTS,
								]}
							/>
						}
					/>
					<Route
						path=":eventId"
						element={
							<ProtectedComponent
								component={EventDetails}
								policiesRequired={[
									POLICIES.READ_CUSTOMER_EVENTS,
								]}
							/>
						}
					/>
				</Route>

				<Route path="/venues">
					<Route
						index
						element={
							<ProtectedComponent
								component={Venues}
								policiesRequired={[
									POLICIES.READ_VENUES,
									POLICIES.READ_CUSTOMER_EVENTS,
								]}
							/>
						}
					/>
					<Route
						path=":venueId"
						element={
							<ProtectedComponent
								component={VenueDetails}
								policiesRequired={[
									POLICIES.READ_VENUES,
									POLICIES.READ_CUSTOMER_EVENTS,
								]}
							/>
						}
					/>
				</Route>
				<Route path="/devices">
					<Route
						index
						element={
							<ProtectedComponent
								component={Devices}
								policiesRequired={[
									POLICIES.READ_CELL,
									POLICIES.READ_CUSTOMER_EVENTS,
									POLICIES.READ_VENUES,
								]}
							/>
						}
					/>
					<Route
						path=":deviceId"
						element={
							<ProtectedComponent
								component={DeviceDetails}
								policiesRequired={[
									POLICIES.READ_CELL,
									POLICIES.READ_CUSTOMER_EVENTS,
									POLICIES.READ_VENUES,
								]}
							/>
						}
					/>
					<Route path="edit">
						<Route
							index
							element={
								<ProtectedComponent
									component={EditDevices}
									policiesRequired={[
										POLICIES.READ_CELL,
										POLICIES.EDIT_CELL,
									]}
								/>
							}
						/>
						<Route
							path="inference"
							element={
								<ProtectedComponent
									component={InferencePanel}
									policiesRequired={[
										POLICIES.READ_CELL,
										POLICIES.EDIT_CELL,
									]}
								/>
							}
						/>
						<Route
							path="mode"
							element={
								<ProtectedComponent
									component={ModePanel}
									policiesRequired={[
										POLICIES.READ_CELL,
										POLICIES.EDIT_CELL,
										POLICIES.EDIT_CELL_MODE,
									]}
								/>
							}
						/>
						{/* TODO: location */}
						{/* xonar admin only below */}
						<Route
							path="software"
							element={
								<ProtectedComponent
									component={SoftwarePanel}
									policiesRequired={[
										POLICIES.READ_CELL,
										POLICIES.EDIT_CELL,
										POLICIES.EDIT_SOFTWARE_VERSION,
									]}
								/>
							}
						/>
						<Route
							path="config"
							element={
								<ProtectedComponent
									component={ConfigPanel}
									policiesRequired={[
										POLICIES.READ_CELL,
										POLICIES.EDIT_CELL,
										POLICIES.EDIT_SOFTWARE_VERSION,
									]}
								/>
							}
						/>
						<Route
							path="security"
							element={
								<ProtectedComponent
									component={PasscodePanel}
									policiesRequired={[POLICIES.EDIT_CELL]}
								/>
							}
						/>
						{/* TODO: mag */}
					</Route>
				</Route>
				<Route path="/people">
					<Route
						index
						element={
							<ProtectedComponent
								component={People}
								policiesRequired={[POLICIES.READ_PEOPLE]}
							/>
						}
					/>
					<Route path="add">
						<Route
							index
							element={
								<ProtectedComponent
									component={AddPerson}
									policiesRequired={[
										POLICIES.READ_PEOPLE,
										POLICIES.EDIT_PEOPLE,
									]}
								/>
							}
						/>
						<Route
							path="batch"
							element={
								<ProtectedComponent
									component={AddBatch}
									policiesRequired={[
										POLICIES.READ_PEOPLE,
										POLICIES.EDIT_PEOPLE,
									]}
								/>
							}
						/>
					</Route>
					<Route path=":personId">
						<Route
							index
							element={
								<ProtectedComponent
									component={PeopleDetails}
									policiesRequired={[POLICIES.READ_PEOPLE]}
								/>
							}
						/>
						<Route
							path="edit"
							element={
								<ProtectedComponent
									component={EditPerson}
									policiesRequired={[
										POLICIES.READ_PEOPLE,
										POLICIES.EDIT_PEOPLE,
									]}
								/>
							}
						/>
					</Route>
				</Route>
				<Route path="/support">
					<Route path="tickets">
						<Route
							index
							element={
								<ProtectedComponent component={ViewTickets} />
							}
						/>
						<Route
							path="add"
							element={
								<ProtectedComponent component={SupportPage} />
							}
						/>
					</Route>
					{/* <Route path="manuals">
						<Route
							index
							element={
								<ProtectedComponent component={ManualsPage} />
							}
						/>
					</Route> */}
					<Route path="training">
						<Route
							index
							element={
								<ProtectedComponent component={Training} />
							}
						/>
						<Route
							path=":videoId"
							element={
								<ProtectedComponent component={TrainingVideo} />
							}
						/>
					</Route>
				</Route>
				<Route path="/shared">
					<Route path="reports">
						<Route
							index
							element={<ProtectedComponent component={Reports} />}
						/>
						<Route path=":reportId" element={<Report />} />
					</Route>
				</Route>
				{/* TODO: uncomment when you need to work on this again */}
				{/* <Route path="walks">
					<Route
						index
						element={
							<ProtectedComponent
								component={DeviceSelectionPage}
							/>
						}
					/>
					<Route
						path=":deviceId"
						element={<ProtectedComponent component={WalksPage} />}
					/>
				</Route> */}
				<Route path="/profile">
					<Route
						index
						element={<ProtectedComponent component={Profile} />}
					/>
				</Route>
				<Route path="*" element={<Error />} />
			</Routes>
		</>
	);
}

export default Sentry.withProfiler(App);
