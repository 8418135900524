import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export const Error = () => {
	const location = useLocation();

	const [status, setStatus] = useState(404);
	const [error, setError] = useState(
		"Oops! The page you are looking for could not be found."
	);

	useEffect(() => {
		if (location.state) {
			setStatus(location.state.status);
			setError(location.state.error);
		}
	}, [location]);

	return (
		<div className="flex items-center justify-center flex-grow min-h-screen bg-inherit sm:min-w-[38rem]">
			<div className="w-[50%] rounded-lg p-8 text-center bg-inherit text-gray-800 dark:text-white">
				<h1 className="mb-4 font-bold text-7xl">{status}</h1>
				<div className="flex flex-col gap-y-2">
					<p className="text-indigo-600 dark:text-indigo-400">
						{error}
					</p>
					<p className="text-sm text-gray-800 dark:text-gray-300">
						Sorry, we couldn’t find the page you’re looking for.
					</p>
				</div>
				<div className="flex items-center justify-center mt-5 text-gray-600 gap-x-6 dark:text-gray-400">
					<Link
						to="/home"
						className="inline-block px-4 py-2 font-semibold rounded "
					>
						<span aria-hidden="true">&larr;</span> Return to Home
					</Link>
					{/* TODO link to support tickets */}
					<Link to="#" className="font-semibold">
						Contact Support <span aria-hidden="true">&rarr;</span>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default Error;
