import { NetworkStatus, useQuery } from "@apollo/client";
import { motion } from "framer-motion";
import { useMemo, useState } from "react";
import { WalkModal } from "../../components/Modals/WalkModal";
import { Pagination } from "../../components/Pagination/Pagination";
import { usePaginationState } from "../../hooks/usePaginationState";
import { GET_THREAT_WALKS } from "../../schemas/queries/Walks";
import { getTime } from "../../utils/timestamps";
import { toCapitalized } from "../../utils/ux";

const RegisteredThreatsCategory = ({ objects, threatId }) => {
	const REDS = ["GUN", "KNIFE"];
	const GREENS = ["NON_WEAPON"];
	const colorClass = (category) => {
		if (REDS.includes(category)) {
			return "text-red-700 rounded-md ring-1 ring-inset bg-red-50 dark:text-red-300 dark:bg-red-500/50 ring-red-600/10";
		}

		if (GREENS.includes(category)) {
			return "text-green-700 rounded-md ring-1 ring-inset bg-green-50 dark:text-green-300 dark:bg-green-500/50 ring-green-600/10";
		}

		return "text-gray-700 rounded-md ring-1 ring-inset bg-gray-50 dark:text-gray-300 dark:bg-gray-500/50 ring-gray-600/10";
	};

	const spanText = (category, name) => {
		let objectType = "Non-Weapon";
		if (REDS.includes(category)) {
			objectType = "Weapon";
		}
		return `${objectType} / ${toCapitalized(name)}`;
	};

	return (
		<>
			{objects.map((threat, i) => {
				const { object } = threat;
				return (
					<div key={`${threatId}-${i}`}>
						<span
							className={`px-2 py-1 text-xs font-medium rounded-md ring-1 ring-inset ${colorClass(
								object.category,
							)}`}
						>
							{spanText(object.category, object.name)}
						</span>
					</div>
				);
			})}
		</>
	);
};

const RegisteredThreatsTableRowSkeleton = () => (
	<tr className="border-b border-gray-100 dark:border-gray-100/20">
		<td colSpan={5} className="py-5 px-4 sm:pl-6 sm:pr-6">
			{/* The line height matches medium text size */}
			<motion.div
				className="h-[1.5rem] w-full mx-auto bg-gray-200 dark:bg-gray-700 rounded opacity-10 [background-image:linear-gradient(90deg,transparent_0%,rgba(0,0,0,0.05)_50%,transparent_100%)] dark:[background-image:linear-gradient(90deg,transparent_0%,rgba(255,255,255,0.5)_50%,transparent_100%)]"
				initial={{ backgroundPosition: "100%" }}
				animate={{ backgroundPosition: "-100%" }}
				transition={{
					duration: 5,
					repeat: Infinity,
					ease: "linear",
				}}
				style={{
					backgroundSize: "200% 100%",
				}}
			/>
		</td>
	</tr>
);

const RegisteredThreatsTableRow = ({ walk, timeZoneId, onClick }) => {
	const {
		id,
		entryTime,
		objectOfInterestPresent,
		cellName,
		annotatedSubjectAreaContents,
		customerDeviceName,
	} = walk;

	const time = useMemo(() => {
		return {
			datetime: new Date(entryTime).toISOString(),
			displayed: getTime(entryTime, timeZoneId),
		};
	}, [entryTime, timeZoneId]);

	const detectedColor = () => {
		const color = objectOfInterestPresent ? "green" : "red";
		return `text-${color}-700 bg-${color}-50 dark:text-${color}-300 dark:bg-${color}-500/50 ring-${color}-600/10`;
	};

	return (
		<tr className="border-b border-gray-100 dark:border-gray-100/20">
			<td className="hidden py-5 pl-6 pr-0 text-left text-gray-700 align-top dark:text-gray-200 tabular-nums sm:table-cell">
				<time dateTime={time.datetime}>{time.displayed}</time>
			</td>{" "}
			<td className="hidden py-5 pl-8 pr-0 text-right text-gray-700 align-top dark:text-gray-200 tabular-nums sm:table-cell">
				{" "}
				{customerDeviceName ? customerDeviceName : cellName}
			</td>
			<td className="py-5 pl-8 pr-0 space-y-1 text-right align-top tabular-nums">
				<RegisteredThreatsCategory
					objects={annotatedSubjectAreaContents}
					threatId={id}
				/>
			</td>
			<td className="py-5 pl-8 pr-0 text-right align-top tabular-nums">
				<span
					className={`px-2 py-1 text-xs font-medium rounded-md ring-1 ring-inset ${detectedColor()}`}
				>
					{objectOfInterestPresent ? "Yes" : "No"}
				</span>
			</td>
			<td className="py-5 pl-8 pr-0 text-right text-gray-700 align-top dark:text-gray-200/50 tabular-nums">
				<span>
					<svg
						className="w-5 h-5 hover:cursor-pointer"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
						onClick={onClick}
					>
						<path d="M3 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM8.5 10a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM15.5 8.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z" />
					</svg>
				</span>
			</td>
		</tr>
	);
};

const RegisteredThreatsTable = ({ eventId, timeZoneId, order, searchTerm }) => {
	const [threats, setThreats] = useState([]);
	const [count, setCount] = useState(0);
	// Pagination
	const initalSkip = 0;
	const initalLimit = 5;
	const { skip, limit, handleSkipChange } = usePaginationState(
		initalSkip,
		initalLimit,
		count,
	);
	// Walk Modal
	const [open, setOpen] = useState(false);
	const [walkData, setWalkData] = useState({});

	const { networkStatus, refetch } = useQuery(GET_THREAT_WALKS, {
		variables: {
			eventId,
			skip: initalSkip,
			limit: initalLimit,
			order,
			searchTerm: searchTerm?.trim(),
		},
		onCompleted: (data) => {
			setThreats(data.eventThreatWalks.items);
			setCount(data.eventThreatWalks.count);
		},
		onError: (error) => {
			console.error(error.message);
		},
		pollInterval: 10000,
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "network-only",
	});

	// TODO: when there are no threats

	if (count < 1) {
		return (
			<>
				<div className="w-full py-8 mt-0 leading-6 text-center bg-white text-md whitespace-nowrap dark:bg-gray-900 dark:text-gray-300">
					{/* TODO more styling */}
					No registered threats.
				</div>
			</>
		);
	}

	return (
		<>
			<table className="w-full mt-0 text-sm leading-6 text-left bg-white whitespace-nowrap dark:bg-gray-900">
				<thead className="text-gray-900 border-b border-gray-200 dark:text-gray-200 dark:border-gray-200/20">
					<tr>
						<th
							scope="col"
							className="hidden py-3 pl-6 pr-0 font-semibold text-left sm:table-cell"
						>
							Arrival Time
						</th>
						<th
							scope="col"
							className="hidden py-3 pl-8 pr-0 font-semibold text-right sm:table-cell"
						>
							Location
						</th>
						<th
							scope="col"
							className="py-3 pl-8 pr-0 font-semibold text-right"
						>
							Category
						</th>
						<th
							scope="col"
							className="py-3 pl-8 pr-0 font-semibold text-right"
						>
							Detected
						</th>
					</tr>
				</thead>
				<tbody>
					{networkStatus === NetworkStatus.loading ||
					networkStatus === NetworkStatus.refetch ||
					networkStatus === NetworkStatus.setVariables
						? Array.from({
								length: limit,
							}).map((_, index) => (
								<RegisteredThreatsTableRowSkeleton
									key={`skeleton-${index}`}
								/>
							))
						: threats.map((walk) => {
								return (
									<RegisteredThreatsTableRow
										walk={walk}
										key={walk.id}
										timeZoneId={timeZoneId}
										onClick={() => {
											setWalkData({
												entryImage: walk.entryImage,
												exitImage: walk.exitImage,
												walkVideo: walk.walkVideo,
												cellName: walk.cellName,
												entryTime: walk.entryTime,
												annotatedSubjectAreaContents:
													walk.annotatedSubjectAreaContents,
												id: walk.id,
												customerDeviceName:
													walk.customerDeviceName,
											});
											setOpen(true);
										}}
									/>
								);
							})}
				</tbody>
			</table>
			<Pagination
				skip={skip}
				onSkip={(newSkip) => handleSkipChange(newSkip, refetch)}
				count={count}
				limit={limit}
				currentAmount={threats.length}
				itemType="threats"
				queryFilters={{
					order,
					searchTerm: searchTerm?.trim(),
				}}
				className="rounded-t-none rounded-b-xl"
			/>
			<WalkModal open={open} setOpen={setOpen} {...walkData}>
				<div className="flex flex-col mt-4 gap-y-2">
					<h2 className="text-base font-medium text-gray-800 dark:text-gray-200">
						Items Detected
					</h2>
					<RegisteredThreatsCategory
						objects={walkData.annotatedSubjectAreaContents}
						threatId={walkData.id}
					/>
				</div>
			</WalkModal>
		</>
	);
};

export const RegisteredThreats = ({ eventId, timeZoneId }) => {
	// ASCENDING === 1
	// DESCENDING === -1
	const [order, setOrder] = useState(1);

	const [searchTerm, setSearchTerm] = useState("");

	const handleSort = (e) => {
		e.preventDefault();
		setOrder(order * -1);
	};

	if (!timeZoneId) {
		return <></>;
	}

	return (
		<div className="mt-8 border border-gray-200 rounded-xl dark:border-gray-200/20">
			<div className="flex flex-row justify-between p-8 pt-6">
				<h2 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
					Registered Threats
				</h2>
				<div className="flex mt-2 rounded-md shadow-sm max-h-10">
					<div className="relative flex focus-within:z-10">
						<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
							<svg
								className="w-5 h-5 text-gray-400"
								viewBox="0 0 20 20"
								fill="currentColor"
								aria-hidden="true"
							>
								<path d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z" />
							</svg>
						</div>

						<input
							type="text"
							name="category"
							id="category"
							className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 bg-white text-gray-900 dark:bg-blue-xonar dark:text-gray-200 dark:border-white/10 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
							placeholder="Category"
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>

						<button
							type="button"
							onClick={handleSort}
							className="relative h-full rounded-l-none border-0 -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-5 text-sm font-semibold text-gray-900 dark:text-gray-200/50 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 dark:hover:bg-blue-xonar/90"
						>
							{/* ASCENDING */}
							{order === 1 && (
								<svg
									className="-ml-0.5 h-5 w-5"
									viewBox="0 0 20 20"
									fill="currentColor"
									aria-hidden="true"
								>
									<path
										fillRule="evenodd"
										d="M2 3.75A.75.75 0 012.75 3h11.5a.75.75 0 010 1.5H2.75A.75.75 0 012 3.75zM2 7.5a.75.75 0 01.75-.75h6.365a.75.75 0 010 1.5H2.75A.75.75 0 012 7.5zM14 7a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02l-1.95-2.1v6.59a.75.75 0 01-1.5 0V9.66l-1.95 2.1a.75.75 0 11-1.1-1.02l3.25-3.5A.75.75 0 0114 7zM2 11.25a.75.75 0 01.75-.75H7A.75.75 0 017 12H2.75a.75.75 0 01-.75-.75z"
										clipRule="evenodd"
									/>
								</svg>
							)}
							{/* DESCENDING */}
							{order === -1 && (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth="1.5"
									stroke="currentColor"
									className="w-5 h-5"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25"
									/>
								</svg>
							)}
							Sort
						</button>
					</div>
				</div>
			</div>
			<RegisteredThreatsTable
				eventId={eventId}
				timeZoneId={timeZoneId}
				order={order}
				searchTerm={searchTerm}
			/>
		</div>
	);
};

export default RegisteredThreats;
