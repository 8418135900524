import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";
import LoadingSpinner from "../../components/LoadingSpinner";
import { usePaginationState } from "../../hooks/usePaginationState";
import { Pagination } from "../../components/Pagination/Pagination";
import { GET_DEVICE_EVENT_METRICS } from "../../schemas/queries/Devices";
import { useCustomerStore } from "../../store/Customer";
import { timestampToDate } from "../../utils/timestamps";

//const DeviceMetricOverviewItem = ({ item, value }) => {
//	return (
//		<li className="px-2 text-xs">
//			{item}
//			<p className="text-xs text-right text-gray-700 dark:text-gray-300">
//				{value}
//			</p>
//		</li>
//	);
//};

//const DeviceMetricOverview = ({ arrivals, alerts, threats, recognized }) => {
//	return (
//		<div className="hidden my-auto sm:block">
//			<ul className="flex flex-row items-end space-x-4">
//				<DeviceMetricOverviewItem item={"Arrivals"} value={arrivals} />
//				<DeviceMetricOverviewItem item={"Alerts"} value={alerts} />
//				<DeviceMetricOverviewItem item={"Threats"} value={threats} />
//				<DeviceMetricOverviewItem
//					item={"Recognized"}
//					value={recognized}
//				/>
//			</ul>
//		</div>
//	);
//};

const DeviceMetric = ({ data }) => {
	const [dateString, displayDateString] = timestampToDate(data.eventTime);
	return (
		<li className="flex justify-between w-full">
			<div className="flex flex-col pt-2">
				<Link
					to={`/events/${data.customerEvent}`}
					className="text-sm hover:underline"
				>
					<h2>{data.customerEventName}</h2>
				</Link>
				<div className="text-xs font-light leading-6 text-gray-700 dark:text-gray-300">
					<time dateTime={dateString}>{displayDateString}</time>
				</div>
			</div>
		</li>
	);
};

export const DeviceMetrics = ({ id }) => {
	const { customerFilter } = useCustomerStore(
		useShallow((state) => ({
			customerFilter: state.customerFilter,
		})),
	);

	const { loading, data } = useQuery(GET_DEVICE_EVENT_METRICS, {
		variables: {
			cellId: id,
			customers: customerFilter,
		},
		onError: (error) => {
			console.error(error);
		},
	});

	const count = useMemo(() => {
		if (loading) return 0;
		return data.deviceEvents?.length;
	}, [data, loading]);

	// Use the new pagination hook
	const { skip, limit, setSkip } = usePaginationState(0, 10, count);

	const eventMetrics = useMemo(() => {
		if (loading) return [];
		const start = skip * limit;
		const end = start + limit;
		return data.deviceEvents.slice(start, end);
	}, [data, loading, skip, limit]);

	return (
		<div className="relative flex flex-col items-center w-full h-full p-8 pt-6 overflow-hidden border border-gray-200 min-h-40 rounded-xl dark:border-gray-200/20">
			{loading ? (
				<div className="flex w-full mx-auto my-auto ">
					<LoadingSpinner className="w-20 h-20 mx-auto my-auto" />
				</div>
			) : (
				<>
					<div className="flex w-full pb-4 mx-auto">
						<div className="mr-auto">
							<h2 className="text-base font-semibold leading-6 text-gray-900 dark:text-gray-200">
								Device Event History
							</h2>
						</div>
					</div>

					<ul className="flex flex-col w-full h-full divide-y divide-gray-100 gap-y-2 dark:text-gray-200 dark:divide-gray-200/20">
						{eventMetrics.map((metric) => (
							<DeviceMetric
								key={metric.customerEvent}
								data={metric}
							/>
						))}
					</ul>
					<Pagination
						skip={skip}
						onSkip={setSkip}
						count={count}
						limit={limit}
						currentAmount={eventMetrics.length}
						className="w-full pt-4 mt-4"
					/>
				</>
			)}
		</div>
	);
};

export default DeviceMetrics;
